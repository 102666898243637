import * as React from "react"
import Layout from '../components/layout'

const IndexPage = () => {
  return (<Layout pageHeading='ProDataStream' pageTitle='ProDataStream'>
    Automate your workflows — Get real-time and actionable insights.
     </Layout>)
}

export default IndexPage
