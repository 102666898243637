import * as React from "react"
import { Link } from "gatsby"
import { container, heading, navLinks, navLinkItem, navLinkText } from "./layout.module.css"

const Layout = ({ pageTitle, pageHeading, children }) => {
  return (
    <main className={container}>
      <title> {pageTitle} </title>
      <h1> {pageHeading} </h1>
        <nav>
            <ul className={navLinks}>
                <li className={navLinkItem}> <Link className={navLinkText} to="/"> Home </Link> </li>
                <li className={navLinkItem}> <Link className={navLinkText} to="/about"> About </Link> </li>
                <li className={navLinkItem}> <Link className={navLinkText} to="/contact"> Contact </Link> </li>
            </ul>
        </nav>
        {children}
    </main>
  )
}

export default Layout
